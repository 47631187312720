import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {
    faBan,
    faBook,
    faGear,
    faGraduationCap,
    faHome,
    faNewspaper,
    faRoute,
    faTachographDigital,
    faUserGroup,
    faUsers,
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons'
import {TFunction} from "i18next";
import {AuthStateUserObject} from "react-auth-kit/dist/types";

export interface menuEntry {
    title: string,
    icon: IconDefinition,
    location?: string,
    disabled?: boolean,
    children?: Array<menuEntry>,
    filter?: [{ name: string, values: Array<string | boolean> }]
}

const menuConfig = (t: TFunction, auth?: AuthStateUserObject): Array<menuEntry> => [
    {
        title: t('home'),
        icon: faHome,
        location: '/',
        disabled: auth?.role !== 'admin', // false TODO:PBF-243
    },
    {
        title: t('userList'),
        icon: faUsers,
        location: '/users',
        disabled: auth?.role !== 'admin', // false TODO:PBF-243
        children: [
            {
                title: t('blacklist'),
                icon: faBan,
                location: '/users',
                filter: [{name: 'blacklist', values: [true]}],
                disabled: auth?.role !== 'admin', // false TODO:PBF-243
            }
        ]
    },
    {
        title: t('travel'),
        icon: faRoute,
        location: '/reisen',
        disabled: true
    },
    {
        title: t('news'),
        icon: faNewspaper,
        location: '/news',
        disabled: true
    },
    {
        title: t('settings'),
        icon: faGear,
        disabled: auth?.role !== 'admin', // false TODO:PBF-243
        children: [
            {
                title: t('management'),
                icon: faUserGroup,
                location: '/settings/admin',
                disabled: true
            },
            {
                title: t('fibel'),
                icon: faBook,
                location: '/settings/manual',
                disabled: true
            },
            {
                title: t('universities'),
                icon: faGraduationCap,
                location: '/universities',
                disabled: auth?.role !== 'admin', // false TODO:PBF-243
            },
            {
                title: t('functions'),
                icon: faWandMagicSparkles,
                location: '/settings/functions',
                disabled: true
            }
        ]
    },
    {
        title: t('tracking'),
        icon: faTachographDigital,
        location: '/tracking',
        disabled: false
    },
]

export default menuConfig