import React from "react";
import {RouterProvider} from "react-router";
import {createBrowserRouter} from 'react-router-dom'
import {CatchClient} from "../components/Error/CatchClient";
import {Layout} from "../layouts/Layout";
import {TopLoadingBarLayout} from "../layouts/TopLoadingBarLayout";
import {ProtectedRoutes} from "./ProtectedRoutes";
import {useAuthUser} from "react-auth-kit";
import {AuthStateUserObject} from "react-auth-kit/dist/types";

const Login = React.lazy(() => import("../pages/login"))
const Home = React.lazy(() => import("../pages/home"))
const UserList = React.lazy(() => import("../pages/users"))
const User = React.lazy(() => import("../pages/user"))
const Universities = React.lazy(() => import("../pages/universities/Universities"))
const Tracking = React.lazy(() => import("../pages/tracking/Tracking"))

const router = (auth?: AuthStateUserObject) => createBrowserRouter([
    {
        path: '/',
        element: <TopLoadingBarLayout/>,
        errorElement: <CatchClient/>,
        children: [
            {path: 'login', element: <Login/>},
            {
                path: '/',
                element: <ProtectedRoutes><Layout/></ProtectedRoutes>,
                children: [
                    {path: '/tracking', element: <Tracking/>},
                    {path: '/', element: auth?.role !== 'Admin' ? <Home/> : <Tracking/>}, //TODO PBF-243 change back to <Home/>
                    {path: '/users', element: <UserList/>},
                    {path: '/user/:id', element: <User/>},
                    {path: '/universities', element: <Universities/>},
                    {path: '/university/:id', element: <Universities/>}
                ]
            }
        ]
    }
])

export default function Router() {
    const auth = useAuthUser()()
    return <RouterProvider router={router(auth ?? undefined)}/>
}

export {router}