import {useTranslation} from "react-i18next";
import menuConfig from "../../../configs/menu.config";
import {MenuEntries} from "./MenuEntries";
import {useAuthUser} from "react-auth-kit";

const MenuElements = () => {
    const {t} = useTranslation()
    const auth = useAuthUser()

    return <ul data-cy="menuElements"
               className="px-0 transition-all menu">
        <MenuEntries entries={menuConfig(t, auth() ?? undefined)}/>
    </ul>
}

export {MenuElements}